<template>
<header class="text-gray-600 body-font">
  <div class="container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center">
    <a class="flex title-font font-medium items-center text-gray-900 mb-4 md:mb-0">
      <i class="fab fa-artstation fa-2x text-teal-300"></i>
      <span class="ml-3 text-lg sm:text-xl"><span style="text-transform:uppercase;">erik<span style="font-weight:900;">uckert</span>|dev&design</span></span>
    </a>
    <nav class="md:mr-auto md:ml-4 md:py-1 md:pl-4 md:border-l md:border-gray-400">
      <a class=" ml-5 hover:text-gray-900"
        href="https://www.linkedin.com/in/erik-uckert"
        target="_blank">
        <i class="fab fa-linkedin text-lg leading-lg"></i>
      </a>
      <a class="mr-5 ml-5 hover:text-gray-900"
        href="https://github.com/ErikUckert"
        target="_blank">
        <i class="fab fa-github text-lg leading-lg "></i>
      </a>
    </nav>
  </div>
</header>
</template>
<script>
export default {
    name: "navbarComp",
}
</script>