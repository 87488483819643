/* eslint-disable */
<template>
<footer class="text-gray-600 body-font">
  <div class="container px-5 py-8 mx-auto flex items-center sm:flex-row flex-col">
    <a class="flex title-font font-medium items-center md:justify-start justify-center text-gray-900">
      <i class="fab fa-artstation fa-2x text-teal-300"></i>
    </a>
    <p class="text-sm text-gray-500 sm:ml-4 sm:pl-4 sm:border-l-2 sm:border-gray-200 sm:py-2 sm:mt-0 mt-4">© 2022 Made with &#10084; by Erik 
    </p>
  </div>
</footer>
</template>
<script>
export default {
    name: "footyComp",
}
</script>