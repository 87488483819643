<template>
  <navbarComp id="head" />
  <heroComp v-bind:showModal=this.showModal v-bind:showHero=this.showHero 
  @show="this.showModal = true, this.showHero = false" />
  <contactComp v-bind:showModal=this.showModal v-bind:showHero=this.showHero @close="this.showModal = false, this.showHero = true" />
  <footyComp @show="this.showModal = true, this.showHero = false, this.scroll(this.navBarId)" />
</template>

<script>
import navbarComp from './components/Navbar.vue'
import heroComp from './components/Hero.vue'
import contactComp from './components/Contact.vue'
import footyComp from './components/Footy.vue'

export default {
  name: 'App',
  components: {
    navbarComp,
    heroComp,
    contactComp,
    footyComp
  },
  data() {
    return {
        showModal: false,
        showHero: true,
        navBarId: "head"
    };
  },
  methods: {
    scroll(id) {  
    document.getElementById(id).scrollIntoView({
      behavior: "smooth"
    });
  }
  }
}
</script>
