/* eslint-disable */
<template>
<section v-if="showHero" class="text-gray-600 body-font">
  <div class="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
    <div class="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
      <h1 class="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900">
        Hej. I'm Erik.
      </h1>
      <h3 class="title-font text-xl mb-4 font-medium text-gray-600">
        Developer, <span class="text-teal-300">Tinker</span>, Senior Application Engineer @ AVL Germany
      </h3>
      <p class="mb-8 leading-relaxed">
        This is my private website. 
        <br>
        Let's talk about
        <span style="font-weight:900;">Webdevelopment</span> and Applications, Blogs or 
        <span style="font-weight:900;">eCommerce</span>.
        <br>
        I am always up for a new project.
        <span style="font-weight:900;">Let's do this together! </span>
      </p>
      <div class="flex justify-center">
        <button class="inline-flex text-white bg-teal-300 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded text-lg"
        id="show-modal" @click="$emit('show')">
          Contact me
        </button>
      </div>
    </div>
    <div class="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
      <img class="object-cover object-center rounded" alt="hero" src="https://images.unsplash.com/photo-1521185496955-15097b20c5fe?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1350&q=80">
    </div>
  </div>
</section>
</template>

<script>
export default {
    name: "heroComp",
    props: {
      showModal: Boolean,
      showHero: Boolean
    }
}
</script>